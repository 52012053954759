import gopher from "./gopher.png";
import duke from "./duke.png";
import unoffical_kt from "./unoffical_kt.png";

export const PROTOCOL = "https://";
export const HOSTS = ["api.uid4oe.dev/ms-go"];
export const PORTS = [""];

export const USERS = "/users/";
export const ADVICES = "/advices/";

export const EXTERNAL_ADVICE_API = "https://api.adviceslip.com/advice";

export const HEADERS = {
  headers: {
    "Content-Type": "application/json;charset=utf-8",
  },
};

export const images = [gopher, unoffical_kt, duke];

export const DEFAULT_USER = {
  id: "",
  name: "",
  age: 1010,
  greeting: "",
  salary: "",
  power: "",
  newUser: true,
};
export const SERVICES_DOWN =
  "Oops! Looks like services for the selected backend is not running.";
